
import { throttle } from 'lodash';

const global = {
    setDummyHeader: function(){
        let $nav = $('.main-header .menu-header-responsive');
        let $dummy = $('.dummy-header');
        let $homepageVideo = $('.homepage-video');
        function fixHeader(){
            // console.log();
            $dummy.css('height', $nav.innerHeight());
            $homepageVideo.css('max-height', (window.innerHeight*0.9) - $nav.innerHeight());
        }
        fixHeader();
        $(window).on('resize', throttle(fixHeader, 100));
    },

    containMenuDropdowns: function(){
        $('.dropdown-toggle').on('click.containMenuDropdowns', function(){
            const $parent = $(this).parent('.dropdown');
            const $menu = $parent.find('.dropdown-menu');
            const topDeadspace = $('.menu-header-responsive').innerHeight();
            const availableHeight = window.innerHeight - topDeadspace;
            console.log(topDeadspace, availableHeight);

            // $menu.css({
            //     maxHeight: availableHeight
            // });
        });
    },

    openLinksInNewTab: function(){
        $('a').attr('target', '_blank');
    },

    init: function(){
        this.openLinksInNewTab();
        this.containMenuDropdowns();
        this.setDummyHeader();
    }
}

$(document).ready(()=>{
    global.init();

    // for anything requiring images or external scripts
    // use the below to wait for a complete load
    $(window).on('load', ()=>{});
})